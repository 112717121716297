$big-pc-width = 1100px
$min-pc-width = 960px
$sp-width = 768px

// Fonts
$sans-serif = -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "Yu Gothic", sans-serif
$serif = Georgia, "Hiragino Mincho ProN", "Yu Mincho", serif

// Media Query
sp()
  @media only screen and (max-width: $sp-width)
    {block}

pc()
  @media only screen and (min-width: $min-pc-width)
    {block}

big()
  @media only screen and (min-width: $big-pc-width)
    {block}
