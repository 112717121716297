.page .heading
  text-align center
  margin-bottom 90px
  color colors('primary')
  .en
    font-family 'Source Sans Pro', sans-serif
    letter-spacing .2em
    font-size 36px
    margin-bottom 15px
  .jp
    font-size 22px
    letter-spacing .1em

  +sp()
    margin-bottom 40px
    .en
      font-size 27px
      margin-bottom 10px
    .jp
      font-size 15px
