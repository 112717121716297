.wrapper5
  position relative
  height 450px
  background-color #FFBA00
  overflow-y hidden
  +sp()
    height 800px
    min-height 420px
    max-height 600px
  .content5
    color #FFFFFF
    display flex
    +sp()
      flex-wrap wrap
    .left
      margin 0 auto
      z-index 1
      +sp()
        z-index 0
        order 2
      img
        width 450px
        +sp()
          width 80vw
          max-width 400px
    .right
      margin 0 auto 0 0
      +sp()
        width 100vw
        margin 0
        order 1
      .heading
        text-align center
        +sp()
          margin 0 auto 40px auto
        .logo
          width 500px
          height 61.66px
          margin 112px 0 50px 0
          +sp()
            width 74vw
            max-width 385px
            margin 100px 0 0 0
      .sub-heading
        line-height 1.8
        margin-bottom 90px
        font-size 1.4rem
        font-weight bold
        +sp()
          font-size 15px
          text-align center
          margin 0 auto
  .content-footer
    position absolute
    bottom 0
    width 100%
    height 30px
    background-image url("../images/gingham_check.png")
    background-repeat repeat
    background-size: 30px 30px

