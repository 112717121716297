.wrapper2
  position relative
  height 540px
  background-image url("../images/bg01.jpg")
  background-size cover
  +sp()
    height 855px
    padding 0
  .bg-mask
    +sp()
      height 100%
      background rgba(255,255,255,0.6)
    .content2
      padding 92px 5px 0 5px
      display flex
      margin 0 auto
      +sp()
        padding-top 0
        flex-wrap wrap
      .left
        display flex
        margin 0 10px 0 auto
        +big()
          padding-left 100px
        +sp()
          order 2
          margin 60px 0
          overflow-x auto
          flex-wrap nowrap
          width 100vw
        img
          height 356px
          width 200px
          filter drop-shadow(0px 3px 6px rgba(0,0,0,0.16))
          +sp()
            flex 0 0 auto
        img:last-child
          +sp()
            width 250px
            padding-right 50px
        img:not(:last-child)
          margin-right 30px
        img:first-child
          +sp()
            margin-left 88px
      .right
        margin 0 auto
        +sp()
          order 1
          width 100vw
          max-width none
          margin 0
          text-align center
        .heading
          color black
          font-size 1.9rem
          font-weight bold
          margin 80px 0 30px 0
          filter drop-shadow(0px 0px 10px rgb(255,255,255))
          +sp()
            width 86vw
            font-size 1.4rem
            margin 140px auto 0 auto
        .sub-heading
          font-size 18px
          font-weight bold
          filter drop-shadow(0px 0px 10px rgb(255,255,255))
          line-height 1.6
          +sp()
            width 90vw
            font-size 0.9rem
            font-weight normal
            margin 60px auto 0 auto
