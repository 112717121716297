.wrapper1
  position relative
  height 708px
  background-color #FFBA00
  .content1
    color #FFFFFF
    display flex
    padding 108px 10px 0 10px
    +sp()
      flex-wrap wrap
      padding 100px 0
    .left
      margin 110px 0 0 auto
      +big()
        margin-right 50px
      +sp()
        width 100vw
        margin 0
      .heading
        margin-bottom 70px
        text-align center
        +sp()
          margin 0 auto 40px auto
        .logo
          max-width 500px
          max-height 61.66px
          +sp()
            width 74vw
            max-width 385px
      .sub-heading
        line-height 1.8
        margin-bottom 70px
        font-size 1.4rem
        font-weight bold
        +sp()
          font-size 15px
          width 81vw
          text-align center
          margin 0 auto 60px auto
      .store
        +sp()
          display flex
          justify-content center
          width 80vw
          margin 0 auto
        .store-icon
          img
            height 50px
            +sp()
              height 40px
        .store-icon:not(:last-child)
          margin-right 30px
          +sp()
            margin-right 30px
    .right
      margin 0 auto 0 50px
      z-index 1
      +sp()
        width 85vw
        margin 0 auto 0 auto
        text-align center
      img
        width 590px
        max-width 100%
        +sp()
          width 320px
          margin 60px auto 0 auto

  .content-footer
    position absolute
    bottom 0
    width 100%
    height 60px
    background-image url("../images/gingham_check.png")
    background-repeat repeat
    background-size: 30px 30px
    +sp()
      height 30px
