header
  display flex
  padding 19px 40px
  justify-content space-between
  align-items: center

  .logo
    width 180px
    height 22.05px
  .menu
    display flex
    margin 0
    list-style none
    li:not(:last-child)
      margin-right 20px
    .menu-item
      font-size 0.9rem
  +sp()
    display none
