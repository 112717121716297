.section
  font-size 15px
  & + &
    margin-top 40px
  .section
    font-size 14px
    margin-top 20px
    .section-title
      font-size 15px
      margin-bottom 10px
  ol
  ul
    padding 0
  p
    margin 0

.section-title
  font-size 17px
  font-weight bold
  margin-bottom 15px
  line-height 1.4

.section-body
  line-height 1.75
  white-space pre-line
  & >>> em
    text-decoration underline

.section-list
  margin-top 10px
  list-style decimal
  &.unordered
    list-style disc

.section-list-item
  line-height 1.5
  margin-left 1em
  & + &
    margin-top 10px

  ol
    list-style lower-latin
    font-size 14px
  li
    margin 5px 0 0 1em

.section-footer
  line-height 1.4
  margin-top 20px
  ul
    list-style disc
  li
    margin 10px 0 0 1em

.sublist
  margin-top 20px
  font-size 13px

.sublist-item
  & + &
    margin-top 15px

.sublist-title
  font-weight bold
  margin-bottom 10px

.sublist-body
  line-height 1.75
  white-space pre-line

.sublist-list
  padding 0
  list-style decimal
  &.unordered
    list-style disc

.sublist-list-item
  line-height 1.4
  margin 2px 0 0 1em

.section-link
  display inline-block
  margin 0.5em auto
  text-decoration underline
