.wrapper3
  position relative
  height 860px
  background linear-gradient(180deg, #FFBA00, #FF8900)
  +sp()
    height 1000px
    padding 0
  .content3
    color #FFFFFF
    display flex
    padding 77px 0 0 0
    .left
      +big()
        padding-left 100px
      +pc()
        margin 0 0 0 auto
      +sp()
        position absolute
        top 150px
        right 60%
      img
        width 322px
        max-width 100%
        +sp()
          width 170px
    .right
      margin 0 auto
      +sp()
        width 100vw
        margin 0
      .heading-wrapper
        +pc()
          display flex
          flex-direction column
          // align-items center
        .heading
          font-size 1.9rem
          font-weight bold
          margin-bottom 10px
          filter drop-shadow(0px 0px 6px rgba(0,0,0,0.20))
          min-height 56px
          +pc()
            display inline-block
          +sp()
            font-size 20px
            margin 100px auto 0 auto
            text-align center
            margin 10px auto
        .sub-heading
          display inline-block
          font-size 1.1rem
          font-weight: bold
          margin-bottom 48px
          filter drop-shadow(0px 0px 6px rgba(0,0,0,0.20))
          line-height 2
          +sp()
            height 150px
            font-size 15px
            font-weight normal
            margin 30px 10px 0 44%
      .figures
        display flex
        +sp()
          margin-top 150px
          overflow-x scroll
          -ms-overflow-style:none;
          flex-wrap nowrap
        .figure
          width 200px
          +sp()
            max-width 200px
          img
            width 200px
            +pc()
              width 100%
            margin-bottom 32px
            filter drop-shadow(0px 3px 6px rgba(0,0,0,0.16))
          figcaption
            filter drop-shadow(0px 0px 6px rgba(0,0,0,0.16))
            text-align center
            .caption
              font-size 1.1rem
              font-weight bold
              margin-bottom 24px
            .description
              letter-spacing -1px
              font-size 0.9rem
        .figure:not(:last-child)
          margin-right 45px
          .description
            width 105%
        .figure:first-child
            +sp()
              margin-left 88px
        .figure:last-child
          .description
            +sp()
              width 130%
              padding-right 20%