#drawer-checkbox
  display none

.drawer-header
  display none
  position fixed
  height 60px
  width 100%
  background-color #ffffff
  z-index 100
  +sp()
    display block
  .logo
    width 200px
    padding 20px
  #drawer-icon
    position absolute
    top 20px
    right 20px
    cursor pointer
    height 30px
    width 30px
    .bar
      position absolute
      background-color #222222
      height 4px
      width 30px
      transition: all 0.3s ease-in-out
    .bar1
      top 0px
    .bar2
      top 15px

#drawer-content
  display flex
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  opacity 0
  z-index -1
  background-color #ffffff
  transition: all 0.3s ease-in-out
  .menu
    height 50vh
    margin auto
    padding 0
    list-style none
    .menu-item
      margin-bottom 30px

#drawer-checkbox:checked ~ #drawer-content
  opacity 1
  z-index 10

#drawer-checkbox:checked ~ .drawer-header #drawer-icon .bar
  height 3px

#drawer-checkbox:checked ~ .drawer-header #drawer-icon .bar1
  top 10px
  transform rotateZ(45deg)

#drawer-checkbox:checked ~ .drawer-header #drawer-icon .bar2
  top 10px
  transform rotateZ(-45deg)
  


