html,
body {
  width: 100%;
}
body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "Yu Gothic", sans-serif;
  line-height: 1.4;
  position: relative;
  color: #333;
  background: #fff;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  text-decoration: none;
  color: #000;
}
*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}
::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}
html {
  box-sizing: border-box;
  cursor: default;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
body {
  margin: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
figcaption,
figure,
main {
  display: block;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
nav ol,
nav ul {
  list-style: none;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
::-moz-selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
}
svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
  resize: vertical;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details,
menu {
  display: block;
}
summary {
  display: list-item;
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
[hidden] {
  display: none;
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-hidden="false"][hidden] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[aria-hidden="false"][hidden]:focus {
  clip: auto;
}
[aria-disabled] {
  cursor: default;
}
.wrapper1 {
  position: relative;
  height: 708px;
  background-color: #ffba00;
}
.wrapper1 .content1 {
  color: #fff;
  display: flex;
  padding: 108px 10px 0 10px;
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 {
    flex-wrap: wrap;
    padding: 100px 0;
  }
}
.wrapper1 .content1 .left {
  margin: 110px 0 0 auto;
}
@media only screen and (min-width: 1100px) {
  .wrapper1 .content1 .left {
    margin-right: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 .left {
    width: 100vw;
    margin: 0;
  }
}
.wrapper1 .content1 .left .heading {
  margin-bottom: 70px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 .left .heading {
    margin: 0 auto 40px auto;
  }
}
.wrapper1 .content1 .left .heading .logo {
  max-width: 500px;
  max-height: 61.66px;
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 .left .heading .logo {
    width: 74vw;
    max-width: 385px;
  }
}
.wrapper1 .content1 .left .sub-heading {
  line-height: 1.8;
  margin-bottom: 70px;
  font-size: 1.4rem;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 .left .sub-heading {
    font-size: 15px;
    width: 81vw;
    text-align: center;
    margin: 0 auto 60px auto;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 .left .store {
    display: flex;
    justify-content: center;
    width: 80vw;
    margin: 0 auto;
  }
}
.wrapper1 .content1 .left .store .store-icon img {
  height: 50px;
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 .left .store .store-icon img {
    height: 40px;
  }
}
.wrapper1 .content1 .left .store .store-icon:not(:last-child) {
  margin-right: 30px;
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 .left .store .store-icon:not(:last-child) {
    margin-right: 30px;
  }
}
.wrapper1 .content1 .right {
  margin: 0 auto 0 50px;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 .right {
    width: 85vw;
    margin: 0 auto 0 auto;
    text-align: center;
  }
}
.wrapper1 .content1 .right img {
  width: 590px;
  max-width: 100%;
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content1 .right img {
    width: 320px;
    margin: 60px auto 0 auto;
  }
}
.wrapper1 .content-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-image: url("../images/gingham_check.png");
  background-repeat: repeat;
  background-size: 30px 30px;
}
@media only screen and (max-width: 768px) {
  .wrapper1 .content-footer {
    height: 30px;
  }
}
.wrapper2 {
  position: relative;
  height: 540px;
  background-image: url("../images/bg01.jpg");
  background-size: cover;
}
@media only screen and (max-width: 768px) {
  .wrapper2 {
    height: 855px;
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper2 .bg-mask {
    height: 100%;
    background: rgba(255,255,255,0.6);
  }
}
.wrapper2 .bg-mask .content2 {
  padding: 92px 5px 0 5px;
  display: flex;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .wrapper2 .bg-mask .content2 {
    padding-top: 0;
    flex-wrap: wrap;
  }
}
.wrapper2 .bg-mask .content2 .left {
  display: flex;
  margin: 0 10px 0 auto;
}
@media only screen and (min-width: 1100px) {
  .wrapper2 .bg-mask .content2 .left {
    padding-left: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper2 .bg-mask .content2 .left {
    order: 2;
    margin: 60px 0;
    overflow-x: auto;
    flex-wrap: nowrap;
    width: 100vw;
  }
}
.wrapper2 .bg-mask .content2 .left img {
  height: 356px;
  width: 200px;
  filter: drop-shadow(0px 3px 6px rgba(0,0,0,0.16));
}
@media only screen and (max-width: 768px) {
  .wrapper2 .bg-mask .content2 .left img {
    flex: 0 0 auto;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper2 .bg-mask .content2 .left img:last-child {
    width: 250px;
    padding-right: 50px;
  }
}
.wrapper2 .bg-mask .content2 .left img:not(:last-child) {
  margin-right: 30px;
}
@media only screen and (max-width: 768px) {
  .wrapper2 .bg-mask .content2 .left img:first-child {
    margin-left: 88px;
  }
}
.wrapper2 .bg-mask .content2 .right {
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .wrapper2 .bg-mask .content2 .right {
    order: 1;
    width: 100vw;
    max-width: none;
    margin: 0;
    text-align: center;
  }
}
.wrapper2 .bg-mask .content2 .right .heading {
  color: #000;
  font-size: 1.9rem;
  font-weight: bold;
  margin: 80px 0 30px 0;
  filter: drop-shadow(0px 0px 10px #fff);
}
@media only screen and (max-width: 768px) {
  .wrapper2 .bg-mask .content2 .right .heading {
    width: 86vw;
    font-size: 1.4rem;
    margin: 140px auto 0 auto;
  }
}
.wrapper2 .bg-mask .content2 .right .sub-heading {
  font-size: 18px;
  font-weight: bold;
  filter: drop-shadow(0px 0px 10px #fff);
  line-height: 1.6;
}
@media only screen and (max-width: 768px) {
  .wrapper2 .bg-mask .content2 .right .sub-heading {
    width: 90vw;
    font-size: 0.9rem;
    font-weight: normal;
    margin: 60px auto 0 auto;
  }
}
.wrapper3 {
  position: relative;
  height: 860px;
  background: linear-gradient(180deg, #ffba00, #ff8900);
}
@media only screen and (max-width: 768px) {
  .wrapper3 {
    height: 1000px;
    padding: 0;
  }
}
.wrapper3 .content3 {
  color: #fff;
  display: flex;
  padding: 77px 0 0 0;
}
@media only screen and (min-width: 1100px) {
  .wrapper3 .content3 .left {
    padding-left: 100px;
  }
}
@media only screen and (min-width: 960px) {
  .wrapper3 .content3 .left {
    margin: 0 0 0 auto;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper3 .content3 .left {
    position: absolute;
    top: 150px;
    right: 60%;
  }
}
.wrapper3 .content3 .left img {
  width: 322px;
  max-width: 100%;
}
@media only screen and (max-width: 768px) {
  .wrapper3 .content3 .left img {
    width: 170px;
  }
}
.wrapper3 .content3 .right {
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .wrapper3 .content3 .right {
    width: 100vw;
    margin: 0;
  }
}
@media only screen and (min-width: 960px) {
  .wrapper3 .content3 .right .heading-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.wrapper3 .content3 .right .heading-wrapper .heading {
  font-size: 1.9rem;
  font-weight: bold;
  margin-bottom: 10px;
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.2));
  min-height: 56px;
}
@media only screen and (min-width: 960px) {
  .wrapper3 .content3 .right .heading-wrapper .heading {
    display: inline-block;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper3 .content3 .right .heading-wrapper .heading {
    font-size: 20px;
    margin: 100px auto 0 auto;
    text-align: center;
    margin: 10px auto;
  }
}
.wrapper3 .content3 .right .heading-wrapper .sub-heading {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 48px;
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.2));
  line-height: 2;
}
@media only screen and (max-width: 768px) {
  .wrapper3 .content3 .right .heading-wrapper .sub-heading {
    height: 150px;
    font-size: 15px;
    font-weight: normal;
    margin: 30px 10px 0 44%;
  }
}
.wrapper3 .content3 .right .figures {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .wrapper3 .content3 .right .figures {
    margin-top: 150px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    flex-wrap: nowrap;
  }
}
.wrapper3 .content3 .right .figures .figure {
  width: 200px;
}
@media only screen and (max-width: 768px) {
  .wrapper3 .content3 .right .figures .figure {
    max-width: 200px;
  }
}
.wrapper3 .content3 .right .figures .figure img {
  width: 200px;
  margin-bottom: 32px;
  filter: drop-shadow(0px 3px 6px rgba(0,0,0,0.16));
}
@media only screen and (min-width: 960px) {
  .wrapper3 .content3 .right .figures .figure img {
    width: 100%;
  }
}
.wrapper3 .content3 .right .figures .figure figcaption {
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.16));
  text-align: center;
}
.wrapper3 .content3 .right .figures .figure figcaption .caption {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 24px;
}
.wrapper3 .content3 .right .figures .figure figcaption .description {
  letter-spacing: -1px;
  font-size: 0.9rem;
}
.wrapper3 .content3 .right .figures .figure:not(:last-child) {
  margin-right: 45px;
}
.wrapper3 .content3 .right .figures .figure:not(:last-child) .description {
  width: 105%;
}
@media only screen and (max-width: 768px) {
  .wrapper3 .content3 .right .figures .figure:first-child {
    margin-left: 88px;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper3 .content3 .right .figures .figure:last-child .description {
    width: 130%;
    padding-right: 20%;
  }
}
.wrapper4 {
  position: relative;
  height: 950px;
  padding-top: 130px;
  background: url("../images/bg02.jpg");
  background-size: cover;
}
@media only screen and (max-width: 768px) {
  .wrapper4 {
    height: 1100px;
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask {
    height: 100%;
    background: rgba(0,0,0,0.5);
  }
}
.wrapper4 .bg-mask .content4 {
  color: #fff;
  display: flex;
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 {
    padding: 0;
  }
}
.wrapper4 .bg-mask .content4 .left {
  margin: 0 0 0 auto;
}
@media only screen and (min-width: 1100px) {
  .wrapper4 .bg-mask .content4 .left {
    padding-left: 80px;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .left {
    position: absolute;
    top: 180px;
    right: 58%;
  }
}
.wrapper4 .bg-mask .content4 .left img {
  width: 322px;
  max-width: 100%;
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .left img {
    width: 170px;
  }
}
.wrapper4 .bg-mask .content4 .right {
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .right {
    width: 100vw;
    margin: 0;
  }
}
@media only screen and (min-width: 960px) {
  .wrapper4 .bg-mask .content4 .right .heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.wrapper4 .bg-mask .content4 .right .heading-wrapper .heading {
  min-height: 80px;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  filter: drop-shadow(0px 0px 10px #000);
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .right .heading-wrapper .heading {
    text-align: center;
    font-size: 20px;
    margin: 100px auto 0 auto;
  }
}
.wrapper4 .bg-mask .content4 .right .heading-wrapper .sub-heading {
  max-width: 600px;
  min-height: 70px;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 30px;
  filter: drop-shadow(0px 0px 10px #000);
  line-height: 2;
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .right .heading-wrapper .sub-heading {
    min-height: 240px;
    font-size: 15px;
    font-weight: normal;
    margin: 30px 10px 0 43%;
  }
}
.wrapper4 .bg-mask .content4 .right .figures {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .right .figures {
    margin-top: 90px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    flex-wrap: nowrap;
  }
}
.wrapper4 .bg-mask .content4 .right .figures .figure {
  width: 250px;
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .right .figures .figure {
    width: 200px;
  }
}
.wrapper4 .bg-mask .content4 .right .figures .figure img {
  width: 250px;
  margin-bottom: 32px;
  filter: drop-shadow(0px 3px 6px rgba(0,0,0,0.16));
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .right .figures .figure img {
    width: 200px;
  }
}
.wrapper4 .bg-mask .content4 .right .figures .figure figcaption {
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.16));
  text-align: center;
}
.wrapper4 .bg-mask .content4 .right .figures .figure figcaption .caption {
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 24px;
}
.wrapper4 .bg-mask .content4 .right .figures .figure figcaption .description {
  font-size: 0.8rem;
}
.wrapper4 .bg-mask .content4 .right .figures .figure:not(:last-child) {
  margin-right: 40px;
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .right .figures .figure:not(:last-child) {
    margin-right: 30px;
  }
}
.wrapper4 .bg-mask .content4 .right .figures .figure:first-child .description {
  width: 95%;
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .right .figures .figure:first-child {
    margin-left: 88px;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper4 .bg-mask .content4 .right .figures .figure:last-child .description {
    width: 130%;
    padding-right: 20%;
  }
}
.wrapper5 {
  position: relative;
  height: 450px;
  background-color: #ffba00;
  overflow-y: hidden;
}
@media only screen and (max-width: 768px) {
  .wrapper5 {
    height: 800px;
    min-height: 420px;
    max-height: 600px;
  }
}
.wrapper5 .content5 {
  color: #fff;
  display: flex;
}
@media only screen and (max-width: 768px) {
  .wrapper5 .content5 {
    flex-wrap: wrap;
  }
}
.wrapper5 .content5 .left {
  margin: 0 auto;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .wrapper5 .content5 .left {
    z-index: 0;
    order: 2;
  }
}
.wrapper5 .content5 .left img {
  width: 450px;
}
@media only screen and (max-width: 768px) {
  .wrapper5 .content5 .left img {
    width: 80vw;
    max-width: 400px;
  }
}
.wrapper5 .content5 .right {
  margin: 0 auto 0 0;
}
@media only screen and (max-width: 768px) {
  .wrapper5 .content5 .right {
    width: 100vw;
    margin: 0;
    order: 1;
  }
}
.wrapper5 .content5 .right .heading {
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .wrapper5 .content5 .right .heading {
    margin: 0 auto 40px auto;
  }
}
.wrapper5 .content5 .right .heading .logo {
  width: 500px;
  height: 61.66px;
  margin: 112px 0 50px 0;
}
@media only screen and (max-width: 768px) {
  .wrapper5 .content5 .right .heading .logo {
    width: 74vw;
    max-width: 385px;
    margin: 100px 0 0 0;
  }
}
.wrapper5 .content5 .right .sub-heading {
  line-height: 1.8;
  margin-bottom: 90px;
  font-size: 1.4rem;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .wrapper5 .content5 .right .sub-heading {
    font-size: 15px;
    text-align: center;
    margin: 0 auto;
  }
}
.wrapper5 .content-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-image: url("../images/gingham_check.png");
  background-repeat: repeat;
  background-size: 30px 30px;
}
.wrapper6 {
  position: relative;
  height: 170px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .wrapper6 {
    height: 300px;
  }
}
.wrapper6 .content6 {
  width: 100%;
  margin: 70px auto 0 auto;
}
@media only screen and (max-width: 768px) {
  .wrapper6 .content6 {
    padding: 200px 0 0 0;
  }
}
.wrapper6 .content6 img.app-icon {
  width: 120px;
  margin-right: 60px;
  filter: drop-shadow(0px 3px 6px rgba(0,0,0,0.16));
}
@media only screen and (max-width: 768px) {
  .wrapper6 .content6 img.app-icon {
    width: 90px;
    position: absolute;
    margin: 0;
    top: 50px;
    left: calc(50% - 45px);
  }
}
.wrapper6 .content6 .store-icon img {
  height: 60px;
}
@media only screen and (max-width: 768px) {
  .wrapper6 .content6 .store-icon img {
    height: 40px;
  }
}
.wrapper6 .content6 .store-icon:not(:last-child) {
  margin-right: 40px;
}
#drawer-checkbox {
  display: none;
}
.drawer-header {
  display: none;
  position: fixed;
  height: 60px;
  width: 100%;
  background-color: #fff;
  z-index: 100;
}
@media only screen and (max-width: 768px) {
  .drawer-header {
    display: block;
  }
}
.drawer-header .logo {
  width: 200px;
  padding: 20px;
}
.drawer-header #drawer-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.drawer-header #drawer-icon .bar {
  position: absolute;
  background-color: #222;
  height: 4px;
  width: 30px;
  transition: all 0.3s ease-in-out;
}
.drawer-header #drawer-icon .bar1 {
  top: 0px;
}
.drawer-header #drawer-icon .bar2 {
  top: 15px;
}
#drawer-content {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
#drawer-content .menu {
  height: 50vh;
  margin: auto;
  padding: 0;
  list-style: none;
}
#drawer-content .menu .menu-item {
  margin-bottom: 30px;
}
#drawer-checkbox:checked ~ #drawer-content {
  opacity: 1;
  z-index: 10;
}
#drawer-checkbox:checked ~ .drawer-header #drawer-icon .bar {
  height: 3px;
}
#drawer-checkbox:checked ~ .drawer-header #drawer-icon .bar1 {
  top: 10px;
  transform: rotateZ(45deg);
}
#drawer-checkbox:checked ~ .drawer-header #drawer-icon .bar2 {
  top: 10px;
  transform: rotateZ(-45deg);
}
.faq-heading {
  font-size: 21px;
  font-weight: 700;
  margin: 40px 0 15px 0;
  line-height: 1.4;
}
.faq-item {
  font-size: 15px;
  line-height: 1.75;
  white-space: pre-line;
  margin-bottom: 20px;
}
footer.footer {
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  footer.footer {
    display: block;
  }
}
footer.footer .foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
@media only screen and (max-width: 768px) {
  footer.footer .foot {
    justify-content: center;
  }
}
footer.footer .logo {
  width: 180px;
  height: 22px;
}
footer.footer .menu {
  display: flex;
  list-style: none;
}
@media only screen and (max-width: 768px) {
  footer.footer .menu {
    display: none;
  }
}
footer.footer .menu li:not(:last-child) {
  margin-right: 20px;
}
footer.footer .menu .menu-item {
  font-size: 0.9rem;
}
footer.footer .copyright {
  background-color: #000;
  height: 40px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
}
@media only screen and (max-width: 768px) {
  footer.footer .copyright {
    font-size: 14px;
    margin-top: 45px;
  }
}
.form {
  margin: 0 80px;
}
@media only screen and (max-width: 768px) {
  .form {
    width: auto;
    margin: 0;
  }
}
.form-item {
  display: flex;
  margin-top: 20px;
}
@media only screen and (max-width: 768px) {
  .form-item {
    display: block;
  }
}
.form-item-title {
  width: 200px;
  line-height: 44px;
}
@media only screen and (max-width: 768px) {
  .form-item-title {
    width: auto;
    line-height: 1.4;
    margin-bottom: 10px;
  }
}
.form-item-body {
  flex: 1;
}
.form-item-body input,
.form-item-body textarea,
.form-item-body select {
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  border: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.form-item-body input {
  line-height: 24px;
}
.form-item-body select {
  height: 46px;
  border-radius: 0;
}
.form-item-body textarea {
  height: 140px;
}
.form-item-body span.wpcf7-not-valid-tip {
  font-size: 13px;
  margin-top: 5px;
}
.form-note {
  font-size: 14px;
  margin: 20px 0 30px 0;
  color: #808080;
}
@media only screen and (max-width: 768px) {
  .form-note {
    font-size: 13px;
    line-height: 1.4;
  }
}
.form-note b {
  color: #000;
}
.form-submit {
  margin-top: 40px;
}
.form-submit input {
  cursor: pointer;
  margin: 0 auto;
  padding: 0;
  border: none;
  outline: none;
  width: 320px;
  display: block;
  line-height: 42px;
  font-size: 15px;
  font-weight: bold;
  color: 'white';
  border-radius: 21px;
  background-color: #ffba00;
}
@media only screen and (max-width: 768px) {
  .form-submit input {
    width: 280px;
    font-size: 14px;
    line-height: 36px;
    border-radius: 18px;
  }
}
.wpcf7-response-output {
  text-align: center;
  border: none !important;
  font-size: 14px;
}
.contact-note {
  background-color: #f9f9f9;
  padding: 32px 24px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .contact-note {
    padding: 24px 16px;
  }
}
.contact-note-heading {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 24px;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .contact-note-heading {
    font-size: 17px;
    margin-bottom: 20px;
  }
}
.contact-note-list-item {
  font-size: 16px;
  line-height: 1.6;
  text-indent: -1em;
  margin-left: 1em;
}
.contact-note-list-item::before {
  content: '・';
}
.contact-note-list-item + .contact-note-list-item {
  margin-top: 12px;
}
.contact-note-list-item em {
  font-style: normal;
  font-weight: bold;
}
.contact-note-list-item.important {
  color: #f23325;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .contact-note-list-item {
    font-size: 15px;
  }
}
header {
  display: flex;
  padding: 19px 40px;
  justify-content: space-between;
  align-items: center;
}
header .logo {
  width: 180px;
  height: 22.05px;
}
header .menu {
  display: flex;
  margin: 0;
  list-style: none;
}
header .menu li:not(:last-child) {
  margin-right: 20px;
}
header .menu .menu-item {
  font-size: 0.9rem;
}
@media only screen and (max-width: 768px) {
  header {
    display: none;
  }
}
.page .heading {
  text-align: center;
  margin-bottom: 90px;
  color: colors('primary');
}
.page .heading .en {
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 0.2em;
  font-size: 36px;
  margin-bottom: 15px;
}
.page .heading .jp {
  font-size: 22px;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 768px) {
  .page .heading {
    margin-bottom: 40px;
  }
  .page .heading .en {
    font-size: 27px;
    margin-bottom: 10px;
  }
  .page .heading .jp {
    font-size: 15px;
  }
}
.page {
  padding: 80px 0 100px;
  width: 960px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .page {
    padding: 80px 20px;
    width: auto;
  }
}
.page-heading {
  position: relative;
  font-size: 21px;
  text-align: center;
  margin-bottom: 55px;
}
.page-heading::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: colors('primary');
  left: 50%;
  margin-left: -20px;
  bottom: -15px;
}
.page-body,
.page-intro {
  margin: 0 70px;
}
@media only screen and (max-width: 768px) {
  .page-body,
  .page-intro {
    margin: 0;
  }
}
.page-intro {
  font-size: 16px;
  line-height: 1.75;
  margin-bottom: 40px;
}
.page-item+.page-item {
  margin-top: 30px;
}
.page-item-heading {
  line-height: 1.4;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
.page-item-body {
  font-size: 14px;
  line-height: 1.75;
}
.page-list {
  list-style: decimal;
  margin-left: 1em;
}
.page-list-item+.page-list-item {
  margin-top: 5px;
}
.page-list-item ul {
  font-size: 13px;
  line-heihgt: 1.4;
  list-style: disc;
  margin: 10px 0 0 1em;
}
.page-list-item li + li {
  margin-top: 3px;
}
.page-bottom {
  text-align: right;
  font-size: 15px;
  margin-top: 50px;
}
.contact {
  margin-top: 60px;
}
.contact-heading {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}
.contact-text {
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 20px;
}
.contact-body {
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-line;
}
.center {
  text-align: center;
}
.section {
  font-size: 15px;
}
.section + .section {
  margin-top: 40px;
}
.section .section {
  font-size: 14px;
  margin-top: 20px;
}
.section .section .section-title {
  font-size: 15px;
  margin-bottom: 10px;
}
.section ol,
.section ul {
  padding: 0;
}
.section p {
  margin: 0;
}
.section-title {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 1.4;
}
.section-body {
  line-height: 1.75;
  white-space: pre-line;
}
.section-body >>> em {
  text-decoration: underline;
}
.section-list {
  margin-top: 10px;
  list-style: decimal;
}
.section-list.unordered {
  list-style: disc;
}
.section-list-item {
  line-height: 1.5;
  margin-left: 1em;
}
.section-list-item + .section-list-item {
  margin-top: 10px;
}
.section-list-item ol {
  list-style: lower-latin;
  font-size: 14px;
}
.section-list-item li {
  margin: 5px 0 0 1em;
}
.section-footer {
  line-height: 1.4;
  margin-top: 20px;
}
.section-footer ul {
  list-style: disc;
}
.section-footer li {
  margin: 10px 0 0 1em;
}
.sublist {
  margin-top: 20px;
  font-size: 13px;
}
.sublist-item + .sublist-item {
  margin-top: 15px;
}
.sublist-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.sublist-body {
  line-height: 1.75;
  white-space: pre-line;
}
.sublist-list {
  padding: 0;
  list-style: decimal;
}
.sublist-list.unordered {
  list-style: disc;
}
.sublist-list-item {
  line-height: 1.4;
  margin: 2px 0 0 1em;
}
.section-link {
  display: inline-block;
  margin: 0.5em auto;
  text-decoration: underline;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
a.link {
  cursor: pointer;
}
body {
  font-family: Hiragino Kaku Gothic ProN, W6;
  min-width: 960px;
}
@media only screen and (max-width: 768px) {
  body {
    min-width: 0px;
  }
}
h1,
h2,
h3 {
  margin: 0;
}
