.wrapper4
  position relative
  height 950px
  padding-top 130px
  background url("../images/bg02.jpg")
  background-size cover
  +sp()
    height 1100px
    padding 0
  .bg-mask
    +sp()
      height 100%
      background rgba(0,0,0,0.5)
    .content4
      color #FFFFFF
      display flex
      +sp()
        padding 0
      .left
        margin 0 0 0 auto
        +big()
          padding-left 80px
        +sp()
          position absolute
          top 180px
          right 58%
        img
          width 322px
          max-width 100%
          +sp()
            width 170px
      .right
        margin 0 auto
        +sp()
          width 100vw
          margin 0
        .heading-wrapper
          +pc()
            display flex
            flex-direction column
            align-items center
          .heading
            min-height 80px
            font-size 1.8rem
            font-weight bold
            margin-bottom 20px
            filter drop-shadow(0px 0px 10px rgb(0,0,0))
            +sp()
              text-align center
              font-size 20px
              margin 100px auto 0 auto
          .sub-heading
            max-width 600px
            min-height 70px
            font-size 1.1rem
            font-weight bold
            margin-bottom 30px
            filter drop-shadow(0px 0px 10px rgb(0,0,0))
            line-height 2
            +sp()
              min-height 240px
              font-size 15px
              font-weight normal
              margin 30px 10px 0 43%
        .figures
          display flex
          +pc()
            // justify-content center
          +sp()
            margin-top 90px
            overflow-x scroll
            -ms-overflow-style:none;
            flex-wrap nowrap
          .figure
            width 250px
            +sp()
              width 200px
            img
              width 250px
              margin-bottom 32px
              filter drop-shadow(0px 3px 6px rgba(0,0,0,0.16))
              +sp()
                width 200px
            figcaption
              filter drop-shadow(0px 0px 6px rgba(0,0,0,0.16))
              text-align center
              .caption
                font-size 1.1rem
                font-weight bold
                letter-spacing -1px
                margin-bottom 24px
              .description
                font-size 0.8rem
          .figure:not(:last-child)
            margin-right 40px
            +sp()
              margin-right 30px
          .figure:first-child
              .description
                width 95%
              +sp()
                margin-left 88px
          .figure:last-child
            .description
              +sp()
                width 130%
                padding-right 20%
