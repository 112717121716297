.faq-heading
  font-size 21px
  font-weight 700
  margin 40px 0 15px 0
  line-height 1.4

.faq-item
  font-size 15px
  line-height 1.75
  white-space pre-line
  margin-bottom 20px
