footer.footer
  justify-content space-between
  align-items: center
  +sp()
    display block
  .foot
    display flex
    justify-content space-between
    align-items center
    padding 0 40px
    +sp()
      justify-content center
  .logo
    width 180px
    height 22px
  .menu
    display flex
    list-style none
    +sp()
      display none
    li:not(:last-child)
      margin-right 20px
    .menu-item
      font-size 0.9rem

  .copyright
    background-color #000000
    height 40px
    color #FFFFFF
    text-align center
    vertical-align: middle
    line-height 40px
    +sp()
      font-size 14px
      margin-top 45px
