.page
  padding 80px 0 100px
  width $min-pc-width
  margin 0 auto
  +sp()
    padding 80px 20px
    width auto

.page-heading
  position relative
  font-size 21px
  text-align center
  margin-bottom 55px
  &::before
    content ''
    position absolute
    width 40px
    height 3px
    background-color colors('primary')
    left 50%
    margin-left -20px
    bottom -15px

.page-body
.page-intro
  margin 0 70px
  +sp()
    margin 0

.page-intro
  font-size 16px
  line-height 1.75
  margin-bottom 40px

.page-item
  &+&
    margin-top 30px

.page-item-heading
  line-height 1.4
  font-size 15px
  font-weight bold
  margin-bottom 5px

.page-item-body
  font-size 14px
  line-height 1.75

.page-list
  list-style decimal
  margin-left 1em

.page-list-item
  &+&
    margin-top 5px
  ul
    font-size 13px
    line-heihgt 1.4
    list-style disc
    margin 10px 0 0 1em
  li + li
    margin-top 3px

.page-bottom
  text-align right
  font-size 15px
  margin-top 50px

.contact
  margin-top 60px

.contact-heading
  font-size 15px
  font-weight bold
  margin-bottom 10px

.contact-text
  font-size 13px
  line-height 1.4
  margin-bottom 20px

.contact-body
  font-size 14px
  line-height 1.6
  white-space pre-line

.center
  text-align center
