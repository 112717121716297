.wrapper6
  position relative
  height 170px
  text-align center
  +sp()
    height 300px
  .content6
    width 100%
    margin 70px auto 0 auto
    +sp()
      padding 200px 0 0 0
    img.app-icon
      width 120px
      margin-right 60px
      filter drop-shadow(0px 3px 6px rgba(0,0,0,0.16))
      +sp()
        width 90px
        position absolute
        margin 0
        top 50px
        left calc(50% - 45px)
    .store-icon
      img
        height 60px
        +sp()
          height 40px
    .store-icon:not(:last-child)
      margin-right 40px
