.form
  margin 0 80px
  +sp()
    width auto
    margin 0

.form-item
  display flex
  margin-top 20px
  +sp()
    display block

.form-item-title
  width 200px
  line-height 44px
  +sp()
    width auto
    line-height 1.4
    margin-bottom 10px

.form-item-body
  flex 1
  input
  textarea
  select
    margin 0
    padding 10px
    box-sizing border-box
    width 100%
    border none
    background-color #ffffff
    border 1px solid #dddddd

  input
    line-height 24px
  select
    height 46px
    border-radius 0
  textarea
    height 140px

  span.wpcf7-not-valid-tip
    font-size 13px
    margin-top 5px

.form-note
  font-size 14px
  margin 20px 0 30px 0
  color: grey;
  +sp()
    font-size 13px
    line-height 1.4
  b
    color: black

.form-submit
  margin-top 40px
  input
    cursor pointer
    margin 0 auto
    padding 0
    border none
    outline none
    width 320px
    display block
    line-height 42px
    font-size 15px
    font-weight bold
    color 'white'
    border-radius 21px
    background-color #FFBA00
    +sp()
      width 280px
      font-size 14px
      line-height 36px
      border-radius 18px

.wpcf7-response-output
  text-align center
  border none !important
  font-size 14px

.contact-note
  background-color #f9f9f9
  padding 32px 24px
  margin-bottom 20px
  +sp()
    padding 24px 16px

.contact-note-heading
  font-size 19px
  font-weight bold
  margin-bottom 24px
  letter-spacing 0.1em
  text-indent 0.1em
  text-align center
  +sp()
    font-size 17px
    margin-bottom 20px

.contact-note-list-item
  font-size 16px
  line-height 1.6
  text-indent -1em
  margin-left 1em
  &::before
    content '・'
  & + &
    margin-top 12px
  em
    font-style normal
    font-weight bold
  &.important
    color #f23325
    font-weight bold
  +sp()
    font-size 15px
